exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-categories-index-js": () => import("./../../../src/pages/blog/categories/index.js" /* webpackChunkName: "component---src-pages-blog-categories-index-js" */),
  "component---src-pages-blog-categories-strapi-category-slug-js": () => import("./../../../src/pages/blog/categories/{strapiCategory.slug}.js" /* webpackChunkName: "component---src-pages-blog-categories-strapi-category-slug-js" */),
  "component---src-pages-blog-categories-topics-lectures-blog-content-image-js": () => import("./../../../src/pages/blog/categories/topics/lectures/blog-content-image.js" /* webpackChunkName: "component---src-pages-blog-categories-topics-lectures-blog-content-image-js" */),
  "component---src-pages-blog-categories-topics-lectures-blog-content-js": () => import("./../../../src/pages/blog/categories/topics/lectures/blog-content.js" /* webpackChunkName: "component---src-pages-blog-categories-topics-lectures-blog-content-js" */),
  "component---src-pages-blog-categories-topics-lectures-blog-content-text-js": () => import("./../../../src/pages/blog/categories/topics/lectures/blog-content-text.js" /* webpackChunkName: "component---src-pages-blog-categories-topics-lectures-blog-content-text-js" */),
  "component---src-pages-blog-categories-topics-lectures-blog-content-video-js": () => import("./../../../src/pages/blog/categories/topics/lectures/blog-content-video.js" /* webpackChunkName: "component---src-pages-blog-categories-topics-lectures-blog-content-video-js" */),
  "component---src-pages-blog-categories-topics-lectures-lecture-content-js": () => import("./../../../src/pages/blog/categories/topics/lectures/lecture-content.js" /* webpackChunkName: "component---src-pages-blog-categories-topics-lectures-lecture-content-js" */),
  "component---src-pages-blog-categories-topics-strapi-topic-slug-js": () => import("./../../../src/pages/blog/categories/topics/{strapiTopic.slug}.js" /* webpackChunkName: "component---src-pages-blog-categories-topics-strapi-topic-slug-js" */),
  "component---src-pages-blog-categories-topics-topic-card-js": () => import("./../../../src/pages/blog/categories/topics/topic-card.js" /* webpackChunkName: "component---src-pages-blog-categories-topics-topic-card-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-about-about-card-js": () => import("./../../../src/pages/portfolio/about/about-card.js" /* webpackChunkName: "component---src-pages-portfolio-about-about-card-js" */),
  "component---src-pages-portfolio-about-about-js": () => import("./../../../src/pages/portfolio/about/about.js" /* webpackChunkName: "component---src-pages-portfolio-about-about-js" */),
  "component---src-pages-portfolio-contact-contact-js": () => import("./../../../src/pages/portfolio/contact/contact.js" /* webpackChunkName: "component---src-pages-portfolio-contact-contact-js" */),
  "component---src-pages-portfolio-contact-contact-social-js": () => import("./../../../src/pages/portfolio/contact/contact-social.js" /* webpackChunkName: "component---src-pages-portfolio-contact-contact-social-js" */),
  "component---src-pages-portfolio-project-project-card-js": () => import("./../../../src/pages/portfolio/project/project-card.js" /* webpackChunkName: "component---src-pages-portfolio-project-project-card-js" */),
  "component---src-pages-portfolio-project-projects-js": () => import("./../../../src/pages/portfolio/project/projects.js" /* webpackChunkName: "component---src-pages-portfolio-project-projects-js" */),
  "component---src-pages-portfolio-skill-skill-area-js": () => import("./../../../src/pages/portfolio/skill/skill-area.js" /* webpackChunkName: "component---src-pages-portfolio-skill-skill-area-js" */),
  "component---src-pages-portfolio-skill-skill-item-js": () => import("./../../../src/pages/portfolio/skill/skill-item.js" /* webpackChunkName: "component---src-pages-portfolio-skill-skill-item-js" */),
  "component---src-pages-portfolio-skill-skills-js": () => import("./../../../src/pages/portfolio/skill/skills.js" /* webpackChunkName: "component---src-pages-portfolio-skill-skills-js" */)
}

